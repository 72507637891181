
<div class="row" >
    <div class="col-md-12" style="position:relative;">
        <highcharts-chart *ngIf="loaded"
                          [Highcharts]="Highcharts"
                          [options]="chartOptions"
                          [constructorType]="chartConstructor"
                          (chartInstance)="setChartInstance($event)"
                          style="width: 100%; height: auto; display: block;"
        ></highcharts-chart>


<!--      <div width="250px" *ngIf="!showEfWeights" style="position:absolute; z-index: 100; margin-left:70%; margin-top:-50%; border: solid;">
        <div class="row" *ngIf="currentReturn">
            <div class="col"><small class="text-muted">Expected volatility:{{currentReturn.vol}}%</small></div>
            <div class="col"><small class="text-muted">Expected return:{{currentReturn.ret}}%</small></div>
        </div>
        <table mat-table [dataSource]="dataSource" matSort class="w-100 mat-small-table table-with-input table-hover">
            <ng-container matColumnDef="company_name" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Company</th>
                <td mat-cell *matCellDef="let element">{{element.company_name}} </td>
            </ng-container>
          <ng-container matColumnDef="Ticker" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Ticker</th>
                <td mat-cell *matCellDef="let element"> {{element.Ticker}} </td>
            </ng-container>
            <ng-container matColumnDef="weight" >
                <th mat-header-cell class="text-right" *matHeaderCellDef mat-sort-header>Weight</th>
                <td mat-cell *matCellDef="let element" class="text-right pr-4"> {{element.weight}}% </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columns"></tr>
            <tr mat-row *matRowDef="let row; columns: columns;" [hidden]="!row.weight"></tr>
        </table>
    </div>
 --> 

    </div>
   
</div>
<div class="row no-print">
    <div class="col-md-12">
        <mat-card>
            <mat-card-content>
                <div class="FlexContainer">
                    <mat-radio-button color="primary" class="radio-bttn"  (change)="onPfChange($event.value)"  value="efficient_portfolio_max_sharpe_ratio" [checked]="optim == 'efficient_portfolio_max_sharpe_ratio'">Max Sharpe PF</mat-radio-button>
                    <mat-radio-button color="primary" class="radio-bttn" (change)="onPfChange($event.value)"  value="efficient_portfolio_max_mu" [checked]="optim == 'efficient_portfolio_max_mu'" >Maximum Return</mat-radio-button>
                    <mat-radio-button color="primary" class="radio-bttn"  [checked]="optim== 'efficient_portfolio_given_mu'" (change)="onPfChange($event.value)" value="efficient_portfolio_given_mu" style="min-width: auto; padding-right: 1rem">Target Return PF</mat-radio-button>
                    <mat-form-field appearance="outline" style="width: 120px" *ngIf="optimizationData">
                        <input matInput type="number" [(ngModel)]="optimizationData.optim_parameters.efficient_target_return">
                        <span matSuffix>%</span>
                    </mat-form-field>
                    <button mat-flat-button color="primary" (click)="calculate()" style="margin-left: 1rem" ><span class="navigation-button">Calculate</span></button>
                    
                </div>
                <div class="FlexContainer">
                    <mat-radio-button color="primary" class="radio-bttn" (change)="onPfChange($event.value)"  value="baseline_portfolio" [checked]="optim == 'baseline_portfolio'" >Baseline PF</mat-radio-button>
                     <mat-radio-button color="primary" class="radio-bttn" (change)="onPfChange($event.value)" value="minimum_variance_portfolio" [checked]="optim == 'minimum_variance_portfolio'" >Minimum Risk</mat-radio-button>
                   <!--  <mat-radio-button color="primary" class="radio-bttn" [checked]="optim == '3'" (change)="onPfChange($event.value)" value="3">BL Tangent PF</mat-radio-button> -->
                    <mat-radio-button color="primary" class="radio-bttn"  (change)="onPfChange($event.value)" value="efficient_portfolio_given_sigma"  [checked]="optim == 'efficient_portfolio_given_sigma'" style="min-width: auto; padding-right: 1rem">Target Risk PF</mat-radio-button>
                    <mat-form-field appearance="outline" style="width: 120px" *ngIf="optimizationData">
                        <input matInput type="number" [(ngModel)]="optimizationData.optim_parameters.efficient_target_risk">
                        <span matSuffix>%</span>
                    </mat-form-field>
                    <button mat-flat-button color="primary" (click)="calculate()" style="margin-left: 1rem"><span class="navigation-button">Calculate</span></button>
                   
                </div>
               
                <div class="efficient-toggle" >
                    <mat-slide-toggle color="primary" [(ngModel)]="showUnconstrainted" class="mb-4" name="show-unconstrainted" (ngModelChange)="onConstraintToggleChange()">Unconstrained</mat-slide-toggle> <br>
                    <mat-slide-toggle color="primary" [(ngModel)]="showStocks" class="mb-4" name="show-stock" (ngModelChange)="onStockToggleChange()">Show Equities</mat-slide-toggle> <br>
<!--                     <mat-slide-toggle color="primary" class="mb-4" name="show-stock">Show CML</mat-slide-toggle> <br>
 -->                    <!-- <mat-slide-toggle color="primary"  [(ngModel)]="showEfWeights" (ngModelChange)="onEfWeightsToggleChange()" name="show-stock">Show EF Weights</mat-slide-toggle> <br> -->
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<!--<div class="row">-->
<!--    <div class="col-md-12">-->
<!--        <table mat-table [dataSource]="resultsTableData" matSort class="w-100 mat-small-table table-with-input table-hover">-->
<!--            <ng-container matColumnDef="companyName">-->
<!--                <th mat-header-cell *matHeaderCellDef mat-sort-header>Company</th>-->
<!--                <td mat-cell *matCellDef="let element"> {{element.companyName}} </td>-->
<!--            </ng-container>-->
<!--            <ng-container matColumnDef="expectedReturn">-->
<!--                <th mat-header-cell *matHeaderCellDef mat-sort-header>Expected return</th>-->
<!--                <td mat-cell *matCellDef="let element"> {{element.expectedReturn}} </td>-->
<!--            </ng-container>-->
<!--            <ng-container matColumnDef="historicalReturn">-->
<!--                <th mat-header-cell class="text-right" *matHeaderCellDef mat-sort-header>historicalReturn</th>-->
<!--                <td mat-cell *matCellDef="let element" class="text-right pr-4"> {{element.historicalReturn}}%</td>-->
<!--            </ng-container>-->
<!--            <ng-container matColumnDef="sharpe">-->
<!--                <th mat-header-cell class="text-right" *matHeaderCellDef mat-sort-header>sharpe</th>-->
<!--                <td mat-cell *matCellDef="let element" class="text-right pr-4"> {{element.sharpe}}%</td>-->
<!--            </ng-container>-->
<!--            <ng-container matColumnDef="allocation">-->
<!--                <th mat-header-cell class="text-right" *matHeaderCellDef mat-sort-header>allocation</th>-->
<!--                <td mat-cell *matCellDef="let element" class="text-right pr-4"> {{element.allocation}}%</td>-->
<!--            </ng-container>-->

<!--            <tr mat-header-row *matHeaderRowDef="columns"></tr>-->
<!--            <tr mat-row *matRowDef="let row; columns: columns;" [hidden]="!row.weight"></tr>-->
<!--        </table>-->


<!--        &lt;!&ndash;    <div class="col-md-4" [hidden]="isPrint">&ndash;&gt;-->
<!--        &lt;!&ndash;        <div class="row" *ngIf="currentReturn">&ndash;&gt;-->
<!--        &lt;!&ndash;            <div class="col"><small class="text-muted">Expected volatility:</small> {{currentReturn.vol}}%</div>&ndash;&gt;-->
<!--        &lt;!&ndash;            <div class="col"><small class="text-muted">Expected return:</small> {{currentReturn.ret}}%</div>&ndash;&gt;-->
<!--        &lt;!&ndash;        </div>&ndash;&gt;-->
<!--        &lt;!&ndash;        <table mat-table [dataSource]="dataSource" matSort class="w-100 mat-small-table table-with-input table-hover">&ndash;&gt;-->
<!--        &lt;!&ndash;            <ng-container matColumnDef="company_name" >&ndash;&gt;-->
<!--        &lt;!&ndash;                <th mat-header-cell *matHeaderCellDef mat-sort-header>Company</th>&ndash;&gt;-->
<!--        &lt;!&ndash;                <td mat-cell *matCellDef="let element"> {{element.company_name}} </td>&ndash;&gt;-->
<!--        &lt;!&ndash;            </ng-container>&ndash;&gt;-->
<!--        &lt;!&ndash;            <ng-container matColumnDef="Ticker" >&ndash;&gt;-->
<!--        &lt;!&ndash;                <th mat-header-cell *matHeaderCellDef mat-sort-header>Ticker</th>&ndash;&gt;-->
<!--        &lt;!&ndash;                <td mat-cell *matCellDef="let element"> {{element.Ticker}} </td>&ndash;&gt;-->
<!--        &lt;!&ndash;            </ng-container>&ndash;&gt;-->
<!--        &lt;!&ndash;            <ng-container matColumnDef="weight" >&ndash;&gt;-->
<!--        &lt;!&ndash;                <th mat-header-cell class="text-right" *matHeaderCellDef mat-sort-header>Weight</th>&ndash;&gt;-->
<!--        &lt;!&ndash;                <td mat-cell *matCellDef="let element" class="text-right pr-4"> {{element.weight}}% </td>&ndash;&gt;-->
<!--        &lt;!&ndash;            </ng-container>&ndash;&gt;-->

<!--        &lt;!&ndash;            <tr mat-header-row *matHeaderRowDef="columns"></tr>&ndash;&gt;-->
<!--        &lt;!&ndash;            <tr mat-row *matRowDef="let row; columns: columns;" [hidden]="!row.weight"></tr>&ndash;&gt;-->
<!--        &lt;!&ndash;        </table>&ndash;&gt;-->
<!--        &lt;!&ndash;    </div>&ndash;&gt;-->
<!--    </div>-->
<!--</div>-->