export class ApiEndpoints {
  static readonly login = 'auth/login';
  static readonly logout = 'api/logout';
  static readonly register = 'account/register';
  static readonly resetPassword = 'account/reset';
  static readonly forgotPassword = 'account/forget';
  static readonly profile = 'profile';
  static readonly me = 'api/me';
  static readonly twoFAReset = 'api/me/setup-2fa';
  static readonly countries = 'countries';
  static readonly cities = 'country/{id}/cities';
  static readonly stock = 'api/stock';
  static readonly stocks = 'api/stocks';
  static readonly charts = 'api/stock/{id}/sections';
  static readonly discoveryFilters = 'api/discovery/filter-data';
  static readonly discovery = 'api/discovery/filter';
  static readonly playground = 'api/discovery/configure';

  static readonly indices = 'api/indices';
  static readonly benchmarks = 'api/benchmarks';
  static readonly benchmarksfull = 'api/benchmarks/list';
  static readonly benchmark = 'api/benchmark';
  static readonly index = 'api/index';
  static readonly clients = 'api/clients';
  static readonly companies = 'api/companies';
  static readonly company = 'api/company';
  static readonly users = 'api/users';
  static readonly user = 'api/user';
  static readonly clientIndices = 'api/client/indices';
  static readonly customPortfolio = 'api/portfolio';
  static readonly currencyRate = 'api/currency-rate/USD/{currency}';

  static readonly client = 'api/client';
  static readonly notifications = 'api/notifications';
  static readonly notification = 'api/notification';
  static readonly purchaseCustomAccess = 'api/purchase/request-customization-access';
  static readonly purchaseIndex = 'api/purchase/request-index-access';

  static readonly datawrapperCreateChart = 'api/data-wrapper/create-chart';
  static readonly datawrapperUploadChartData = 'api/data-wrapper/chart/:id/upload';

  static readonly portfolio = 'api/portfolio';
  static readonly portfolios = 'api/portfolios';
  static readonly portfolioFilter = 'api/portfolio/filter';
  static readonly stockFilters = 'api/stock/filters';
  static readonly etfFilters = 'api/etf/filters';
  static readonly fundFilters = 'api/fund/filters';
  static readonly portfolioBulkDelete = 'api/portfolio/bulk-delete';
  static readonly portfolioShare = 'api/portfolio/:id/share';
  static readonly portfolioView = 'api/portfolio/:id/view';
  static readonly portfolioFork = 'api/portfolio/:id/fork';
  static readonly portfolioUnshare = 'api/portfolio/:id/un-share';
  static readonly portfolioLeaveShare = 'api/portfolio/:id/leave-share';
  static readonly portfolioShareTargets = 'api/portfolio/:id/share-targets';
  static readonly portfolioModifiedOptimWeights = 'api/portfolio/:id/modified-optim-weights';
  static readonly portfolioCompanyUpload = 'api/portfolio/upload-companies';
  static readonly companyCloseAdj = 'api/company/:id/close-adj';
  static readonly fundCloseAdj = 'api/fund/:id/close-adj';

  static readonly companyIs = 'api/company/:security/is';
  static readonly fundIs = 'api/fund/:security/is';

  static readonly portfolioMarketCaps = 'api/portfolio/market-caps';

  static readonly portfolioConstraint = 'api/portfolio-constraint';
  static readonly portfolioViews = 'api/portfolio-view';

  static readonly watchlists = 'api/watch-lists';
  static readonly watchlist = 'api/watch-list';
  static readonly watchlistitems = 'api/watchlistitems';
}
