<div [class]="'table-header-select-wrapper ' + name">
    <mat-select
        #selectField
        [(ngModel)]="model"
        [required]="isRequired"
        [multiple]="isMultiple"
        [disabled]="isDisabled"
        [placeholder]="labelPrefix + label"
        (openedChange)="searchText = ''"
        (ngModelChange)="onModelChange()"
        (closed)="onPanelClosed()"
        (opened)="keepData = false"
        panelClass="table-header-select"
        disableOptionCentering
    >
        <mat-select-trigger *ngIf="isInTableHeader" class="input-filter-header">
            {{label}} <span *ngIf="model?.length > 0">({{model.length}})</span>
        </mat-select-trigger>
        <div class="select-input-search">
            <input placeholder="Search" matInput [(ngModel)]="searchText" >
        </div>
        <div class="selection-container">
            <button type="button" mat-button color="primary" (click)="selectAll()">Select all</button>
            <button type="button" mat-button color="primary" (click)="deselectAll()">Deselect all</button>
        </div>
        <mat-option *ngFor="let item of list | search: searchText" [hidden]="item.hidden == true || (hideMarked && item.marked == true)" [value]="item.id" >{{item.name}}</mat-option>
        <mat-option *ngIf="hasNullFilter" value="" class="null-option" (click)="nullOnly();selectField.close()">NULL</mat-option>
        <div class="selection-container bottom-buttons">
            <button type="button" mat-button color="primary" (click)="onOKClick(); selectField.close()">OK</button>
            <button type="button" mat-button color="primary" (click)="onPanelCloseOrCancel();selectField.close()">Cancel</button>
        </div>
    </mat-select>
</div>
